import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const Promemoria: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  const simpleNavigate = () => {
    router.push("/summary", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "400",
              paddingRight: " 20px",
              paddingLeft: "20px",
              paddingTop: "120px",
              marginBottom: "80px",
            }}
          >
            Imposta un promemoria durante la giornata per creare il tuo
            resoconto giornalierio
          </p>
          <IonDatetime
            className="ion-date-time"
            presentation="time"
          ></IonDatetime>
        </div>
        <div>
          <button
            onClick={() => simpleNavigate()}
            style={{ marginBottom: "25px", marginTop: "40px" }}
            className="loginBtn"
          >
            imposta promemoria
          </button>
        </div>
      </div>
    </IonPage>
  );
};

export default Promemoria;
