import { useState, useContext, useEffect } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const Summary: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    simpleNavigate();
  }, []);

  const simpleNavigate = () => {
    setTimeout(() => {
      router.push("/signup", "forward", "push");
    }, 4000);
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col items-center "
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <img style={{ marginTop: "100px" }} src="assets/loader.gif" alt="" />
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "500",
              paddingRight: " 20px",
              paddingLeft: "20px",
            }}
          >
            Stiamo elaborando il tuo resoconto giornaliero, attendi qualche
            secondo!
          </p>
        </div>
        <div></div>
      </div>
    </IonPage>
  );
};

export default Summary;
