import { useState, useContext } from "react";
import ProgressBar from "../components/ProgressBar";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";
import { ProgressContext } from "../Context";

const Tab4: React.FC = () => {
  const router = useIonRouter();
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isOutline, setIsOutline] = useState<any>(false);
  const [selectedButton, setSelectedButton] = useState(-1);
  const { progress, setProgress } = useContext(ProgressContext);

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  const simpleNavigate = () => {
    router.push("/5", "forward", "push");
    const interval = setTimeout(() => {
      setProgress((prevProgress: number) => prevProgress + 0.25);
    }, 500);

    return () => clearTimeout(interval);
  };

  return (
    <IonPage className="scroll">
      <div
        onClick={() => setIsOutline(true)}
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <ProgressBar></ProgressBar>
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "50px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          Come ti senti oggi?
        </h2>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div className="flex flex-row justify-center items-center">
            <div
              style={{
                marginRight: "20px",
                outline: selectedButton === 0 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 0 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(0)}
            >
              <img src="assets/Group1.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Alla grande</p>
            </div>
            <div
              style={{
                outline: selectedButton === 1 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 1 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(1)}
            >
              <img src="assets/Group2.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Bene</p>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <div
              style={{
                marginRight: "20px",
                outline: selectedButton === 2 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 2 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(2)}
            >
              <img src="assets/Group3.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Okay</p>
            </div>
            <div
              style={{
                outline: selectedButton === 3 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 3 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(3)}
            >
              <img src="assets/Group4.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Male</p>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center">
            <div
              style={{
                marginRight: "20px",
                outline: selectedButton === 4 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 4 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(4)}
            >
              <img src="assets/Group5.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Malissimo</p>
            </div>
            <div
              style={{
                outline: selectedButton === 5 ? "2px solid #49B18C" : "none",
                backgroundColor: selectedButton === 5 ? "#ffffff" : "#f5f5f5",
              }}
              className="mainCard2"
              onClick={() => handleClick(5)}
            >
              <img src="assets/Group6.png" alt="" />
              <p style={{ color: "#19204D", marginTop: "10px" }}>Terribile</p>
            </div>
          </div>
        </div>

        {selectedButton >= 0 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Continua
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Tab4;
