import { useState, useEffect } from "react";
import { IonProgressBar } from "@ionic/react";
import { ProgressContext } from "../Context";
import { useContext } from "react";

function ProgressBar() {
  const { progress, setProgress } = useContext(ProgressContext);
  useEffect(() => {}, []);

  return <IonProgressBar value={progress}></IonProgressBar>;
}
export default ProgressBar;
