import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { useIonRouter } from "@ionic/react";
import LoadingScreen from "../components/LoadingScreen";
import "./Home.css";
import logo from "./logo.svg";
import illustrazione from "/assets/illu2.png";
import Desktopview from "../components/Desktopview";
import Tab1 from "./Tab1";

const Home: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState<number>(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    setTimeout(() => setLoading(false), 3000);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const router = useIonRouter();

  const simpleNavigate = () => {
    router.push("/1", "forward", "replace");
  };

  const isMobile = width <= 768;
  return (
    <>
      <IonPage>
        <IonContent className="no-scroll">
          {!loading ? (
            <div
              style={{ height: "100%" }}
              className="flex flex-col justify-center items-center"
            >
              <div
                style={{ height: "65%" }}
                className="flex flex-col justify-center items-center"
              >
                <img
                  style={{ width: "72px" }}
                  src="assets/themulogo.png"
                  alt=""
                />
                <img src="/assets/illu2.png" alt="" />
              </div>
              <div
                className="flex flex-col justify-center items-center"
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "35%",
                  borderTopLeftRadius: "20px",
                  borderTopRightRadius: "20px",
                }}
              >
                <h2 style={{ paddingBottom: "25px" }} className="heading1">
                  Questo è un payoff
                </h2>
                <button
                  onClick={() => simpleNavigate()}
                  style={{ marginBottom: "25px" }}
                  className="mainBtn"
                >
                  Iniziamo
                </button>
                <h3 className="blarge">Hai già un'account?</h3>
                <button
                  style={{ paddingTop: "10px", color: "#ACACAC" }}
                  className="blarge"
                >
                  Accedi
                </button>
              </div>
            </div>
          ) : (
            <LoadingScreen />
          )}
        </IonContent>
      </IonPage>
    </>
  );
};

export default Home;
