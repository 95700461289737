import { Route } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { useState, useEffect } from "react";
import Home from "./pages/Home";
import Tab1 from "./pages/Tab1";
import Tab2 from "./pages/Tab2";
import Tab3 from "./pages/Tab3";
import Tab4 from "./pages/Tab4";
import Tab5 from "./pages/Tab5";
import Tab6 from "./pages/Tab6";
import Tab7 from "./pages/Tab7";
import Tab8 from "./pages/Tab8";
import Caregiver2 from "./pages/Caregiver2";
import Caregiver3 from "./pages/Caregiver3";
import Caregiver4 from "./pages/Caregiver4";
import Caregiver5 from "./pages/Caregiver5";
import Caregiver6 from "./pages/Caregiver6";
import Caregiver7 from "./pages/Caregiver7";
import Caregiver8 from "./pages/Caregiver8";
import Caregiver9 from "./pages/Caregiver9";
import { IonReactRouter } from "@ionic/react-router";
import { ProgressContext, UserContext } from "./Context";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import Desktopview from "./components/Desktopview";
import LoadingScreen from "./components/LoadingScreen";
import Caregiver1 from "./pages/Caregiver1";
import Notifications from "./pages/Notifications";
import Promemoria from "./pages/Promemoria";
import Summary from "./pages/Summary";
import Login from "./pages/Signup";
import Signup from "./pages/Signup";
import MailSignup from "./pages/MailSignup";
import Success from "./pages/Success";
import MusicRooms from "./pages/MusicRooms";
import Player from "./pages/Player";
import Player10 from "./pages/Player10";
import Player15 from "./pages/Player15";
import Player20 from "./pages/Player20";
import Player25 from "./pages/Player25";
import Dashboard from "./pages/Dashboard";
import Tab9 from "./pages/Tab9";
import Settings from "./pages/Settings";
import End from "./pages/End";

setupIonicReact();

const App: React.FC = () => {
  const [progress, setProgress] = useState(0.25);
  const [isMobile, setIsMobile] = useState(false);
  const [name, setName] = useState("");

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div>
      {isMobile ? (
        <IonApp>
          <IonReactRouter>
            <IonRouterOutlet>
              <LoadingScreen />
              <ProgressContext.Provider value={{ progress, setProgress }}>
                <UserContext.Provider value={{ name, setName }}>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/1" component={Tab1} />
                  <Route exact path="/2" component={Tab2} />
                  <Route exact path="/3" component={Tab3} />
                  <Route exact path="/4" component={Tab4} />
                  <Route exact path="/5" component={Tab5} />
                  <Route exact path="/6" component={Tab6} />
                  <Route exact path="/7" component={Tab7} />
                  <Route exact path="/8" component={Tab8} />
                  <Route exact path="/9" component={Tab9} />
                  <Route exact path="/caregiver1" component={Caregiver1} />
                  <Route exact path="/caregiver2" component={Caregiver2} />
                  <Route exact path="/caregiver3" component={Caregiver3} />
                  <Route exact path="/caregiver4" component={Caregiver4} />
                  <Route exact path="/caregiver5" component={Caregiver5} />
                  <Route exact path="/caregiver6" component={Caregiver6} />
                  <Route exact path="/caregiver7" component={Caregiver7} />
                  <Route exact path="/caregiver8" component={Caregiver8} />
                  <Route exact path="/caregiver9" component={Caregiver9} />
                  <Route
                    exact
                    path="/notifications"
                    component={Notifications}
                  />
                  <Route exact path="/promemoria" component={Promemoria} />
                  <Route exact path="/summary" component={Summary} />
                  <Route exact path="/signup" component={Signup} />
                  <Route exact path="/mail-signup" component={MailSignup} />
                  <Route exact path="/success" component={Success} />
                  <Route exact path="/musicrooms" component={MusicRooms} />
                  <Route exact path="/player" component={Player} />
                  <Route exact path="/player10" component={Player10} />
                  <Route exact path="/player15" component={Player15} />
                  <Route exact path="/player20" component={Player20} />
                  <Route exact path="/player25" component={Player25} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/settings" component={Settings} />
                  <Route exact path="/end" component={End} />
                </UserContext.Provider>
              </ProgressContext.Provider>
            </IonRouterOutlet>
          </IonReactRouter>
        </IonApp>
      ) : (
        <div
          style={{ height: "100vh", fontFamily: "poppins" }}
          className="flex flex-col items-center "
        >
          <img style={{ marginTop: "100px" }} src="/assets/illu2.png" alt="" />
          <h2
            style={{
              padding: "0 220px",
              textAlign: "center",
              fontSize: "40px",
            }}
            className="heading1"
          >
            This a mobile app prototype, to view it correctly put the broswer in
            mobile view or visit it from your phone, thank you.
          </h2>
          <p style={{ marginTop: "20px" }} className="blarge">
            - Themu Team
          </p>
        </div>
      )}
    </div>
  );
};

export default App;
