import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonImg,
  IonItem,
  IonPage,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState, useEffect } from "react";
import { useIonRouter } from "@ionic/react";
import LoadingScreen from "../components/LoadingScreen";
import "./Home.css";
import logo from "./logo.svg";
import illustrazione from "/assets/illu2.png";
import Desktopview from "../components/Desktopview";
import Tab1 from "./Tab1";

const Success: React.FC = () => {
  const router = useIonRouter();

  const simpleNavigate = () => {
    router.push("/musicrooms", "forward", "replace");
  };

  return (
    <>
      <IonPage>
        <IonContent className="no-scroll">
          <div
            style={{ height: "100%" }}
            className="flex flex-col justify-center items-center"
          >
            <div
              style={{ height: "50%" }}
              className="flex flex-col justify-center items-center"
            >
              <img src="assets/Illustrazione1.png" alt="" />
            </div>
            <div
              className="flex flex-col justify-center items-center"
              style={{
                backgroundColor: "white",
                width: "100%",
                height: "50%",
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            >
              <h2
                style={{ paddingBottom: "25px", textAlign: "center" }}
                className="heading1"
              >
                Account creato con successo!
              </h2>
              <p style={{ textAlign: "center", padding: "0 20px" }}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
              </p>
              <button
                onClick={() => simpleNavigate()}
                style={{ margin: "25px 0" }}
                className="mainBtn"
              >
                Accedi alla stanza musicale
              </button>

              <button
                style={{ color: "#19204D", textDecorationLine: "underline" }}
                className="blarge"
              >
                Salta per ora
              </button>
            </div>
          </div>
        </IonContent>
      </IonPage>
    </>
  );
};

export default Success;
