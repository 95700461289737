import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
  IonToggle,
} from "@ionic/react";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

const Settings: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  const simpleNavigate = () => {
    router.push("/dashboard", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-start flex-col mt-4"
        >
          <div className="flex flex-col items-start justify-center">
            <button onClick={() => simpleNavigate()}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h2
              style={{
                color: "#19204D",
                fontSize: "24px",
                fontWeight: "500",
                marginTop: "20px",
              }}
            >
              Impostazioni
            </h2>
            <p
              style={{
                color: "#19204D",
                fontSize: "20px",
                fontWeight: "500",
                marginTop: "50px",
                marginBottom: "10px",
              }}
            >
              Generali
            </p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="setting-div"
          >
            <p>Attiva le notifiche</p>
            <IonToggle slot="end"> </IonToggle>
          </div>
          <div className="setting-div"> Promemoria quotidiano </div>
          <div className="setting-div"> Promemoria quotidiano </div>
          <div className="flex flex-col items-start justify-center">
            <p
              style={{
                color: "#19204D",
                fontSize: "20px",
                fontWeight: "500",
                marginTop: "50px",
                marginBottom: "10px",
              }}
            >
              Account
            </p>
          </div>
          <div
            style={{ display: "flex", flexDirection: "row" }}
            className="setting-div"
          >
            <p>Apple Health</p>
            <IonToggle slot="end"> </IonToggle>
          </div>
          <div className="setting-div"> Promemoria quotidiano </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Settings;
