import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";
import { UserContext } from "../Context";
type Props = {
  name: string;
};

const Caregiver2: React.FC<Props> = ({}) => {
  const router = useIonRouter();
  const [isVisible, setIsVisible] = useState<any>(false);
  const { name, setName } = useContext(UserContext);

  const simpleNavigate = () => {
    router.push("/caregiver3", "forward", "push");
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "120px",
            paddingRight: "20px",
            paddingLeft: "20px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          Qual è il nome della persona che assisti?
        </h2>

        <input
          onChange={(e) => setName(e.target.value)}
          className=" text-center"
          style={{
            fontSize: "40px",
            fontFamily: "'poppins', sans serif",
            backgroundColor: "white",
            border: "none",
            color: "#19204D",
            outline: "none",
            marginTop: "150px",
          }}
          onFocus={() => setIsVisible(true)}
          placeholder="Nome"
        />
        {!isVisible && (
          <p style={{ fontWeight: "300" }} className="bsmall">
            TOCCA PER SCRIVERE
          </p>
        )}
        {isVisible && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Caregiver2;
