import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";
import { ProgressContext } from "../Context";
import ProgressBar from "../components/ProgressBar";

const Tab7: React.FC = () => {
  const router = useIonRouter();
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isOutline, setIsOutline] = useState<any>(false);
  const { progress, setProgress } = useContext(ProgressContext);

  const simpleNavigate = () => {
    router.push("/8", "forward", "push");
  };

  return (
    <IonPage className="scroll">
      <div
        onClick={() => setIsOutline(true)}
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <ProgressBar></ProgressBar>
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "80px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          In che zona si localizza il dolore?
        </h2>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div className="mainCard">
            <button className="blarge">Zona superiore</button>
            <img
              style={{ marginLeft: "120px" }}
              src="assets/superiore.png"
              alt=""
            />
          </div>
          <div
            onClick={() => setIsVisible(true)}
            style={{ outline: isOutline ? "2px solid #49B18C" : "none" }}
            className="mainCard"
          >
            <button className="blarge">Zona centrale</button>
            <img
              style={{ marginLeft: "120px" }}
              src="assets/centrale.png"
              alt=""
            />
          </div>
          <div className="mainCard">
            <button className="blarge">Zona inferiore</button>
            <img
              style={{ marginLeft: "120px" }}
              src="assets/inferiore.png"
              alt=""
            />
          </div>
        </div>

        {isVisible && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Continua
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Tab7;
