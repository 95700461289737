import { IonContent } from "@ionic/react";
import React from "react";

function LoadingScreen() {
  return (
    <IonContent>
      <div className="loading">
        {/*Logo Animation goes here*/}
        <img style={{ width: "139px" }} src="assets/themulogo.png" alt="" />
      </div>
    </IonContent>
  );
}

export default LoadingScreen;
