import { useState, useContext, useEffect, useRef } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const MusicRooms: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedTimer, setSelectedTimer] = useState<string | null>(null);

  const parentRef = useRef<HTMLDivElement>(null);
  const childRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!childRef.current) {
      return;
    }
    if (!parentRef.current) {
      return;
    }
    const childNode = childRef.current;
    const parentNode = parentRef.current;

    if (childNode === parentNode.firstChild) {
      parentNode.scrollLeft = 0;
    } else if (childNode === parentNode.lastChild) {
      parentNode.scrollLeft = parentNode.scrollWidth;
    } else {
      parentNode.scrollLeft =
        childNode.offsetLeft -
        parentNode.offsetLeft -
        parentNode.clientWidth / 2 +
        childNode.clientWidth / 2;
    }
  }, [childRef, parentRef, selectedTimer]);

  const timerToPathMap: { [timer: string]: string } = {
    "5:00": "/player",
    "10:00": "/player10",
    "15:00": "/player15",
    "20:00": "/player20",
    "25:00": "/player25",
  };

  const handleTimerClick = (timer: string) => {
    setSelectedTimer(timer);
  };

  const simpleNavigate = () => {
    if (selectedTimer) {
      router.push(timerToPathMap[selectedTimer], "forward", "push");
    }
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "80px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
            lineHeight: "160%",
            fontWeight: "500",
          }}
        >
          Per ottenere migliori benefici ti consigliamo un ascolto di almeno 15
          minuti
        </h2>
        <div
          ref={parentRef}
          style={{ marginTop: "100px" }}
          className="music-timers-container"
        >
          {Object.keys(timerToPathMap).map((timer, index) => (
            <div
              key={timer}
              style={{
                marginRight: "20px",
                opacity: selectedTimer === timer ? 1 : 0.2,
                backgroundColor:
                  selectedTimer === timer ? "#F1F1F1" : "transparent",
                ...(index === 0 ? { marginLeft: "100px" } : {}),
                ...(index === 4 ? { marginRight: "80px" } : {}),
              }}
              className="music-timer"
              onClick={() => handleTimerClick(timer)}
              ref={selectedTimer === timer ? childRef : undefined}
            >
              <p>{timer}</p>
            </div>
          ))}
        </div>
        {selectedTimer && (
          <button
            onClick={simpleNavigate}
            style={{ marginTop: "200px" }}
            className="mainBtn"
            disabled={!selectedTimer}
          >
            Vai al player
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default MusicRooms;
