import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";
import ProgressBar from "../components/ProgressBar";
import { ProgressContext } from "../Context";

const Caregiver7: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { progress, setProgress } = useContext(ProgressContext);

  const simpleNavigate = () => {
    router.push("/Caregiver8", "forward", "push");
    const interval = setTimeout(() => {
      setProgress((prevProgress: number) => prevProgress + 0.25);
    }, 500);

    return () => clearTimeout(interval);
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver8", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <ProgressBar></ProgressBar>
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "50px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
            paddingRight: "20px",
            paddingLeft: "20px",
          }}
        >
          Che tipo di attività ha mostrato oggi?
        </h2>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div
            style={{
              outline: selectedButton === 0 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 0 ? "#ffffff" : "#f5f5f5",
              padding: "20px",
              height: "100px",
            }}
            className="mainCard"
            onClick={() => handleClick(0)}
          >
            <button style={{ textAlign: "left" }} className="blarge">
              Posizione quieta, si muove come di consueto
            </button>
            <img src="assets/Group2.png" alt="" />
          </div>
          <div
            style={{
              outline: selectedButton === 1 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 1 ? "#ffffff" : "#f5f5f5",
              padding: "20px",
              height: "100px",
            }}
            className="mainCard"
            onClick={() => handleClick(1)}
          >
            <button style={{ textAlign: "left" }} className="blarge">
              Teso, agitato, si muove avanti e indietro
            </button>
            <img src="assets/Group3.png" alt="" />
          </div>
          <div
            style={{
              outline: selectedButton === 2 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 2 ? "#ffffff" : "#f5f5f5",
              padding: "20px",
              height: "100px",
            }}
            className="mainCard"
            onClick={() => handleClick(2)}
          >
            <button style={{ textAlign: "left" }} className="blarge">
              Rigido, inarcato o compie movimenti a scatto
            </button>
            <img src="assets/Group5.png" alt="" />
          </div>
        </div>

        {selectedButton === 0 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
        {selectedButton === 1 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
        {selectedButton === 2 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Caregiver7;
