import { useState, useContext, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlay,
  faPause,
  faCirclePlay,
  faCirclePause,
} from "@fortawesome/free-solid-svg-icons";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const Player20: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [duration, setDuration] = useState<any>("0:00");
  const [currentTime, setCurrentTime] = useState(0);
  const [audioName, setAudioName] = useState<string | undefined>(undefined);
  const [remainingDuration, setRemainingDuration] = useState<string>("0:00");
  const [timerDuration, setTimerDuration] = useState<any>("0:00");
  const [time, setTime] = useState(
    new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
  );
  useEffect(() => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.addEventListener("loadedmetadata", () => {
        setDuration(audioElement.duration);
        setRemainingDuration(
          `${Math.floor(audioElement.duration / 60)
            .toString()
            .padStart(1, "0")}:${Math.floor(audioElement.duration % 60)
            .toString()
            .padStart(2, "0")}`
        );
        setTimerDuration(
          `${Math.floor(audioElement.currentTime / 60)
            .toString()
            .padStart(1, "0")}:${Math.floor(audioElement.currentTime % 60)
            .toString()
            .padStart(2, "0")}`
        );
      });
      audioElement.addEventListener("timeupdate", () => {
        setCurrentTime(audioElement.currentTime);
        setRemainingDuration(
          `${Math.floor((duration - audioElement.currentTime) / 60)
            .toString()
            .padStart(1, "0")}:${Math.floor(
            (duration - audioElement.currentTime) % 60
          )
            .toString()
            .padStart(2, "0")}`
        );
        setTimerDuration(
          `${Math.floor(audioElement.currentTime / 60)
            .toString()
            .padStart(1, "0")}:${Math.floor(audioElement.currentTime % 60)
            .toString()
            .padStart(2, "0")}`
        );
      });
    }

    return () => {
      if (audioElement) {
        audioElement.removeEventListener("loadedmetadata", () => {});
        audioElement.removeEventListener("timeupdate", () => {});
      }
    };
  }, [duration]);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      const audio = audioRef.current;
      setAudioName(audio.src.split("/").pop()?.split(".")[0] || "");
    }
  }, [audioRef]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const handlePlayPause = () => {
    const audioElement = audioRef.current;
    if (audioElement) {
      if (audioElement.paused) {
        audioElement.play();
        setIsVisible(false);
      } else {
        audioElement.pause();
        setIsVisible(true);
      }
    }
  };

  const handleTimelineChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const audioElement = audioRef.current;
    if (audioElement) {
      audioElement.currentTime = (e.target.valueAsNumber * duration) / 100;
    }
  };

  const simpleNavigate = () => {
    router.push("/musicrooms", "forward", "push");
  };

  const simpleNavigate2 = () => {
    router.push("/dashboard", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  const progress = (currentTime / duration) * 100;

  return (
    <IonPage>
      <div
        style={{ height: "100%" }}
        className="flex flex-col justify-center items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <div style={{ width: "100%" }}>
            <h2 style={{ fontSize: "20px" }} className="mt-14">
              {audioName}
            </h2>
            <p style={{ fontSize: "12px" }} className="mb-14">
              autore
            </p>
            <p
              className="mb-6 mt-20"
              style={{ fontSize: "48px", fontWeight: "300" }}
            >
              {time}
            </p>
            <audio ref={audioRef} src="/assets/20min.mp3"></audio>
            <button onClick={handlePlayPause}>
              {" "}
              <FontAwesomeIcon
                icon={
                  audioRef.current && audioRef.current.paused ? faPlay : faPause
                }
                style={{
                  color: "#19204D",
                  background: "white",
                  padding: "22px",
                  borderRadius: "50%",
                  height: "40px",
                  width: "40px",
                }}
              />
            </button>

            <div style={{ marginTop: "150px" }}>
              {isVisible && (
                <div className="mb-4">
                  <button
                    onClick={() => simpleNavigate()}
                    style={{
                      background: "#19204D",
                      color: "white",
                      height: "45px",
                      width: "160px",
                      marginRight: "20px",
                    }}
                  >
                    CAMBIA STANZA
                  </button>
                  <button
                    onClick={() => simpleNavigate2()}
                    style={{
                      background: "white",
                      color: "#19204D",
                      height: "45px",
                      width: "160px",
                    }}
                  >
                    TERMINA SESSIONE
                  </button>
                </div>
              )}
              <input
                type="range"
                min={0}
                max={100}
                value={(currentTime / duration) * 100}
                onChange={handleTimelineChange}
                className="timeline"
                style={{
                  background: `linear-gradient(to right, #19204D 0%, #19204D ${progress}%, #828282 ${progress}%, #828282 100%)`,
                }}
              />
              <div
                style={{ width: "100%" }}
                className="flex flex-row justify-around items-center"
              >
                <p style={{ transform: "translateX(-73px)" }}>
                  {timerDuration}
                </p>
                <p style={{ transform: "translateX(73px)" }}>
                  {remainingDuration}
                </p>
              </div>
              <div className="flex flex row justify-around">
                <button>
                  <img
                    style={{ width: "48px", transform: "translateX(-73px)" }}
                    src="assets/heart.png"
                    alt=""
                  />
                </button>
                <button>
                  <img
                    style={{ width: "48px", transform: "translateX(73px)" }}
                    src="assets/airplay.png"
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Player20;
