import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const MailSignup: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [isOutline, setIsOutline] = useState<any>(false);
  const [isOutline2, setIsOutline2] = useState<any>(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value);
    if (email !== "" && password !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const handlePasswordChange = (e: any) => {
    setPassword(e.target.value);
    if (email !== "" && password !== "") {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  const simpleNavigate = () => {
    router.push("/success", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "500",
              paddingTop: "100px",
              paddingRight: " 20px",
              paddingLeft: "20px",
            }}
          >
            Signup con e-mail
          </p>
        </div>
        <div className="flex flex-col justify-center items-start mt-24">
          <label
            style={{
              textAlign: "left",
              fontSize: "16px",
              color: "#19204D",
              fontFamily: "Poppins",
              paddingBottom: "10px",
            }}
            htmlFor="mail"
          >
            Inserisci la tua mail
          </label>
          <input
            onChange={handleEmailChange}
            onClick={() => {
              setIsOutline2(false);
              setIsOutline(true);
            }}
            style={{
              marginBottom: "32px",
              outline: isOutline ? "2px solid #A687FF" : "none",
            }}
            className="input"
            placeholder="Mail"
            type="mail"
          />
          <label
            style={{
              textAlign: "left",
              fontSize: "16px",
              color: "#19204D",
              fontFamily: "Poppins",
              paddingBottom: "10px",
            }}
            htmlFor="password"
          >
            Inserisci la tua Password
          </label>
          <input
            onChange={handlePasswordChange}
            onClick={() => {
              setIsOutline2(true);
              setIsOutline(false);
            }}
            style={{ outline: isOutline2 ? "2px solid #A687FF" : "none" }}
            className="input"
            placeholder="Password"
            type="password"
          />
        </div>
        <button
          disabled={isDisabled}
          onClick={() => simpleNavigate()}
          style={{ marginTop: "32px", opacity: isDisabled ? 0.5 : 1 }}
          className="mainBtn"
        >
          Registrati
        </button>
      </div>
    </IonPage>
  );
};

export default MailSignup;
