import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const Signup: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);
  const [isOutline, setIsOutline] = useState<any>(false);

  const simpleNavigate = () => {
    router.push("/mail-signup", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        onClick={() => setIsOutline(true)}
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col justify-center items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "500",
              paddingRight: " 20px",
              paddingLeft: "20px",
            }}
          >
            Resoconto pronto!
          </p>
          <div>
            <img src="assets/pronto.gif" alt="" />
          </div>
          <p style={{ marginBottom: "25px" }}>Adesso crea un account!</p>
          <div>
            <button style={{ marginBottom: "25px" }} className="loginBtn">
              {" "}
              <img
                style={{ transform: "translate(-20px, 0px)" }}
                src="assets/apple.png"
                alt=""
              />{" "}
              Accedi con Apple
            </button>
            <button className="loginBtn">
              <img
                style={{ transform: "translate(-13px, 0px)" }}
                src="assets/google.png"
                alt=""
              />
              Accedi con Google
            </button>
            <p style={{ margin: "32px 0px " }}>oppure</p>
            <button
              onClick={() => simpleNavigate()}
              style={{ outline: isOutline ? "2px solid #49B18C" : "none" }}
              className="mainBtn"
            >
              Registrati con la tua mail
            </button>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Signup;
