import { useState } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";

const Caregiver1: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);

  const simpleNavigate = () => {
    router.push("/Caregiver2", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "120px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          Quanti anni ha?
        </h2>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div
            style={{
              outline: selectedButton === 0 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 0 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(0)}
          >
            <button onClick={() => setIsVisible(true)} className="blarge">
              Meno di 8 anni
            </button>
          </div>
          <div
            style={{
              outline: selectedButton === 1 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 1 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(1)}
          >
            <button onClick={() => setIsVisibleCG(true)} className="blarge">
              Tra gli 8 anni e i 17 anni
            </button>
          </div>
          <div
            style={{
              outline: selectedButton === 2 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 2 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(2)}
          >
            <button className="blarge">Più di 18 anni</button>
          </div>
        </div>

        {selectedButton === 0 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
        {selectedButton === 1 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
        {selectedButton === 2 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Caregiver1;
