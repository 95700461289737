import { useState } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
} from "@ionic/react";

const Tab3: React.FC = () => {
  const router = useIonRouter();
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const [isOutline, setIsOutline] = useState<any>(false);
  const [selectedButton, setSelectedButton] = useState(-1);

  const simpleNavigate = () => {
    router.push("/4", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage className="scroll">
      <div
        onClick={() => setIsOutline(true)}
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "120px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          Utilizzi l'app per te stesso o per assistere un'altra persona?
        </h2>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div
            style={{
              outline: selectedButton === 0 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 0 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(0)}
          >
            <button onClick={() => setIsVisible(true)} className="blarge">
              Utilizzo l'app per me stesso
            </button>
          </div>
          <div
            style={{
              outline: selectedButton === 1 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 1 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(1)}
          >
            <button onClick={() => setIsVisibleCG(true)} className="blarge">
              Assisto un'altra persona
            </button>
          </div>
          <div
            style={{
              outline: selectedButton === 2 ? "2px solid #49B18C" : "none",
              backgroundColor: selectedButton === 2 ? "#ffffff" : "#f5f5f5",
            }}
            className="mainCard"
            onClick={() => handleClick(2)}
          >
            <button className="blarge">
              Utilizzo l'app per me stesso e assito un'altra persona
            </button>
          </div>
        </div>

        {selectedButton === 0 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
        {selectedButton === 1 && (
          <button
            onClick={() => simpleNavigateCG()}
            style={{ marginTop: "32px" }}
            className="mainBtn"
          >
            Prosegui
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Tab3;
