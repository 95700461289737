import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonRange,
} from "@ionic/react";
import { ProgressContext } from "../Context";
import ProgressBar from "../components/ProgressBar";

const Tab6: React.FC = () => {
  const router = useIonRouter();
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isOutline, setIsOutline] = useState<any>(false);
  const { progress, setProgress } = useContext(ProgressContext);
  const [value, setValue] = useState<any>(0);

  const simpleNavigate = () => {
    router.push("/7", "forward", "push");
    const interval = setTimeout(() => {
      setProgress((prevProgress: number) => prevProgress + 0.25);
    }, 500);

    return () => clearTimeout(interval);
  };

  return (
    <IonPage className="scroll">
      <div
        onClick={() => setIsOutline(true)}
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <ProgressBar></ProgressBar>
        <h2
          className="heading1"
          style={{
            fontSize: "24px",
            paddingTop: "80px",
            fontFamily: "'poppins', sans serif",
            textAlign: "center",
          }}
        >
          Quanto è forte il dolore?
        </h2>
        <p
          style={{ color: "#19204D", marginTop: "10px", marginBottom: "15px" }}
        >
          Trascina l'indicatore e imposta il grado di dolore
        </p>

        {value >= 0 && value <= 33 ? (
          <div style={{}} className="painCard">
            <img src="assets/Group1.png" alt="" />
            <p style={{ color: "#19204D", marginTop: "10px" }}>Nessun Dolore</p>
          </div>
        ) : value > 33 && value <= 66 ? (
          <div style={{}} className="painCard">
            <img src="assets/Group5.png" alt="" />
            <p style={{ color: "#19204D", marginTop: "10px" }}>Intenso</p>
          </div>
        ) : (
          <div style={{}} className="painCard">
            <img src="assets/Group6.png" alt="" />
            <p style={{ color: "#19204D", marginTop: "10px" }}>Molto intenso</p>
          </div>
        )}
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="mt-16"
        >
          <div
            style={{
              height: "150px",
              display: "flex",
              flexDirection: "column",
              marginTop: "20px",
            }}
          >
            <input
              className="pain"
              type="range"
              min="0"
              max="100"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              style={{ transform: "rotate(-90deg)" }}
            />
          </div>
        </div>

        {value > 66 && (
          <button
            onClick={() => simpleNavigate()}
            style={{ marginTop: "10px" }}
            className="mainBtn"
          >
            Continua
          </button>
        )}
      </div>
    </IonPage>
  );
};

export default Tab6;
