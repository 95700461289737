import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
} from "@ionic/react";
import { UserContext } from "../Context";

const Tab9: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  const simpleNavigate = () => {
    router.push("/notifications", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage className="scroll">
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "400",
              paddingRight: " 20px",
              paddingLeft: "20px",
              paddingTop: "80px",
              paddingBottom: "40px",
            }}
          >
            Hai localizzato 2 zone di dolore
          </p>
          <div className="flex flex-row items-center justify-center">
            <div
              style={{
                width: "159px",
                height: "169px",
                background: "#F5F5F5",
                borderRadius: "12px",
                marginRight: "20px",
              }}
            >
              <img
                style={{ width: "200px" }}
                src="assets/bodycentral.png"
                alt=""
              />
            </div>
            <div
              style={{
                width: "159px",
                height: "169px",
                background: "#F5F5F5",
                borderRadius: "12px",
              }}
            >
              <img
                style={{ width: "200px" }}
                src="assets/bodypost.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-start items-start">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              width: "159px",
              height: "169px",
              background: "#F5F5F5",
              borderRadius: "12px",
              transform: "translateX(-90px)",
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            <p style={{ fontSize: "32px", color: "#BABCCA" }}>+</p>
            <p style={{ color: "#BABCCA" }}>AGGIUNGI ZONA</p>
          </div>
        </div>
        <div>
          <button
            onClick={() => simpleNavigate()}
            style={{ marginBottom: "25px" }}
            className="mainBtn"
          >
            Continua
          </button>
        </div>
      </div>
    </IonPage>
  );
};

export default Tab9;
