import { useState, useContext, useEffect, useRef, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
  IonDatetime,
  IonPicker,
  IonButton,
  IonModal,
  IonIcon,
  useIonViewWillEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import { arrowDown } from "ionicons/icons";
import { UserContext } from "../Context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";

const Dashboard: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const location = useLocation();

  const modal = useRef<HTMLIonModalElement>(null);
  const page = useRef(null);

  const [presentingElement, setPresentingElement] =
    useState<HTMLElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setShowModal(true);
    }, 500);
  }, []);
  function dismiss() {
    modal.current?.dismiss();
  }

  const simpleNavigate = () => {
    router.push("/settings", "forward", "push");
  };

  const simpleNavigate2 = () => {
    router.push("/end", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        onClick={() => setShowModal(false)}
        style={{ height: "100%", background: "#D9DEF6" }}
        className="flex flex-col  items-center"
      >
        <div
          className={`overlay ${showModal ? "show" : ""}`}
          onClick={() => setShowModal(false)}
        ></div>
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <div className="flex flex-row justify-start items-center mt-4">
            <div
              style={{ width: "350px" }}
              className="flex flex-row justify-start items-center "
            >
              <img style={{ width: "50px" }} src="/assets/avatar.png" alt="" />
              <p className="ml-2">{name}</p>
            </div>
            <FontAwesomeIcon
              onClick={() => simpleNavigate()}
              fontSize={25}
              icon={faGear}
            />
          </div>
          <div className="mr-4 ml-4 mb-8 mt-4">
            <img src="/assets/todaymood.png" alt="" />
          </div>
        </div>
        <div
          style={{
            background: "white",
            height: "100%",
            width: "100%",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <h2
            style={{
              color: "#19204D",
              fontSize: "18px",
              fontWeight: "500",
              textAlign: "left",
              margin: "10px 20px",
            }}
          >
            Panoramica settimanale
          </h2>
          <div className="flex flex-row items-center justify-center">
            <div className="dashboard-card1">
              <p
                style={{
                  color: "#19204D",
                  fontSize: "32px",
                  fontWeight: "500",
                }}
              >
                5
              </p>
              <p style={{ color: "#19204D" }}>giorni consecutivi</p>
            </div>
            <div className="dashboard-card2">
              <p
                style={{
                  color: "#19204D",
                  fontSize: "32px",
                  fontWeight: "500",
                }}
              >
                8
              </p>
              <p style={{ color: "#19204D" }}>controlli</p>
            </div>
          </div>
          <div className="flex justify-center items-center mr-4 ml-4">
            <img style={{ width: "300px" }} src="/assets/chart.png" alt="" />
          </div>
          <div className="flex justify-center items-center">
            <button
              onClick={() => simpleNavigate2()}
              style={{ marginTop: "20px" }}
              className="mainBtn"
            >
              Continua
            </button>
          </div>
        </div>
      </div>

      <div
        className="flex flex-col justify-start items-center pt-8"
        style={{
          width: "100%",
          height: showModal ? "80%" : "0%",
          bottom: "-40px",
          position: "absolute",
          transition: "all 0.5s",
          transform: showModal ? "translateY(0%)" : "translateY(100%)",
          zIndex: "2",
        }}
      >
        <div
          className="flex flex-col justify-start items-center pt-4"
          style={{
            background: "white",
            width: "100%",
            height: "100%",
            borderTopLeftRadius: "16px",
            borderTopRightRadius: "16px",
          }}
        >
          <div className="hr"></div>
          <p style={{ fontSize: "14px", color: "#19204D" }}>
            Resoconto quotidiano effettuato!
          </p>
          <h2 style={{ fontSize: "24px", fontWeight: "500", color: "#19204D" }}>
            3 giorni di fila!
          </h2>
          <img style={{ width: "250px" }} src="/assets/flower.png" alt="" />
          <img style={{ width: "350px" }} src="/assets/days.png" alt="" />
          <button
            onClick={() => setShowModal(false)}
            style={{ marginTop: "20px" }}
            className="mainBtn"
          >
            Continua
          </button>
        </div>
      </div>
    </IonPage>
  );
};

export default Dashboard;
