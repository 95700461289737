import { useState, useContext } from "react";
import {
  IonContent,
  IonPage,
  useIonRouter,
  IonItem,
  IonInput,
  IonLabel,
  IonAlert,
} from "@ionic/react";
import { UserContext } from "../Context";

const Notifications: React.FC = () => {
  const router = useIonRouter();
  const [selectedButton, setSelectedButton] = useState(-1);
  const [isVisible, setIsVisible] = useState<any>(false);
  const [isVisibleCG, setIsVisibleCG] = useState<any>(false);
  const { name, setName } = useContext(UserContext);
  const [showAlert, setShowAlert] = useState(false);

  const simpleNavigate = () => {
    router.push("/Promemoria", "forward", "push");
  };

  const simpleNavigateCG = () => {
    router.push("/Caregiver1", "forward", "push");
  };

  const handleClick = (index: number) => {
    setSelectedButton(index);
  };

  return (
    <IonPage>
      <div
        style={{ height: "100%", backgroundColor: "white" }}
        className="flex flex-col  items-center"
      >
        <div
          style={{ fontFamily: "'poppins', sans serif", textAlign: "center" }}
          className="flex justify-center items-center flex-col"
        >
          <p
            className="heading1"
            style={{
              fontSize: "20px",
              fontFamily: "'poppins', sans serif",
              textAlign: "center",
              fontWeight: "400",
              paddingRight: " 20px",
              paddingLeft: "20px",
              paddingTop: "120px",
            }}
          >
            Vuoi ricevere una notifica giornaliera per compilare il
            questionario?
          </p>
          <div className="noti">
            <div
              className="flex flex-col justify-center items-center"
              style={{}}
            >
              <p
                className="flex flex-col justify-center items-center"
                style={{
                  borderBottom: "1px solid #00000033",
                  height: "95px",
                }}
              >
                Themu vorrebbe mandarti delle notifiche
              </p>
            </div>
            <div
              style={{ height: "40px" }}
              className="flex flex-row justify-center items-center"
            >
              <button
                onClick={() => simpleNavigate()}
                style={{
                  width: "140px",
                  borderRight: "1px solid #00000033",
                  height: "40px",
                }}
              >
                Non consentire
              </button>
              <button
                onClick={() => setShowAlert(true)}
                style={{ width: "140px", color: "#1963C7" }}
              >
                Consenti
              </button>
              <IonAlert
                isOpen={showAlert}
                onDidDismiss={() => setShowAlert(false)}
                header="Themu vorrebbe inviarti delle notifiche"
                message="Le notifiche possono includere avvisi, suoni e badge icone configurabili in impostazioni"
                cssClass="custom-alert"
                buttons={[
                  {
                    text: "Consenti",
                    handler: () => {
                      simpleNavigate();
                    },
                  },
                  {
                    text: "Consenti nel riepilogo programmato",
                    handler: () => {
                      simpleNavigate();
                    },
                  },
                  {
                    text: "Non consentire",
                    handler: () => {
                      simpleNavigate();
                    },
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </IonPage>
  );
};

export default Notifications;
